import { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Route, useHistory, Switch, Redirect } from "react-router-dom";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import {
  setAuthorizationState,
  setOktaToken,
  setOktaUser,
} from "reducer/oktaSlice";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_ENV === 'PROD' ? process.env.REACT_APP_OKTA_PROD_ISSUER : process.env.REACT_APP_OKTA_NON_PROD_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID, // Browser / Authorize
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: [
    "openid",
    "profile",
    "email",
  ],
  tokenManager: {
    autoRenew: false,
    storage: "sessionStorage",
  },
  pkce: true,
});

export const getOktaAuthUsername = async () => {
  let name = '';
  if(oktaAuth.authStateManager) {
    name = oktaAuth.authStateManager._authState.idToken.claims.name;
  }
  return name;
};

export const getOktaAuthToken = () => {
  return oktaAuth.authStateManager._authState.accessToken.accessToken;
};
const Routes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if(oktaAuth && oktaAuth.authStateManager) {
      dispatch(setAuthorizationState(oktaAuth.authStateManager.getAuthState()));
      oktaAuth.getUser().then((user) => {
        dispatch(setOktaUser(user));
      });
      dispatch(setOktaToken(oktaAuth?.getAccessToken()));
    }

  });

  const history = useHistory();
  const AuditEvents = lazy(() => import("components/auditEvents/AuditEvents"));

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute
            key="audit-events"
            path={["/", "/audit-events"]}
            title="Audit Events"
            component={AuditEvents}
            nav={true}
            secure={true}
            breadcrumb="Audit Events"
          />
          <Redirect from="/" to="/audit-events" />
        </Switch>
      </Security>
    </>
  );
};

export default Routes;