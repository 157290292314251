import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSyncCall } from "../utils/apiAdapter";
import { getOktaAuthToken } from "routes/routes";
import { apiSource } from "constants/Constants";

export const initialState = {
  auditFlag: false,
  orderHeaderFlag: false,
  searchParameters: {
    type: "Order UUID",
    searchValue: "",
    startTime: new Date(new Date().setDate(new Date().getDate() - 30)),
    endTime: new Date(),
  },
  ordersPagination: {
    anchor: 0,
    perPage: 10,
  },
  orderHeaderInfoDisplay: {},
  orderSummaryRequestData: {},
  orderEvents: {},
  orderSummaryError: "",
  orderHeaderRequest: {},
  orderHeaderResponse: {},
  orderHeaderError: "",
  activeTab: 'audit-order-uuid',
  globalRegion: 'GC',
};

export const getOrderEvents = createAsyncThunk(
  "auditEventsSlice/getOrderEvents",
  async (input, { getState }) => {
    try {

      const state = getState();
      const params = state.auditEvents.searchParameters;

      const token = "Bearer " + getOktaAuthToken();
      const headers = {
        Authorization: token,
      };

      const orderId = params.searchValue;
      let api = `${apiSource}/order_mgmt/audit_event/v1/${orderId}`

      const requestParams = { count: input.perPage ?? 10, anchor: input.anchor ?? 0 };
      requestParams['geo'] = state.auditEvents.globalRegion;

      if (input["type"] === "Order UUID") {
        requestParams['filter'] = `id(${orderId})`;
      }
      else if (input["type"] === "Customer PONumber") {
        requestParams['filter'] = `customerPurchaseOrderNumber(${orderId})`;
      }

      const res = getSyncCall(api, headers, requestParams);
      return res;
    } catch (e) {
      console.log(e);
    }
  }
);

export const getOrderAuditEvents = createAsyncThunk(
  "auditEventsSlice/getOrderAuditEvents",
  (input) => {
    // const api = baseConfig.api.auditEventApi
    try {
      //const api ="https://microcks.mp-ordermgmt-test.nikecloud.com:8080/rest/STO+Pipeline/1.0.0/audit"
      let api = "https://mp-osdaudit-test.nike.com/order_mgmt/osd_audit/v1/";
      api =
        "https://reprocess-api-test.mp-ordermgmt-test.nikecloud.com/order_mgmt/osd_orders/audit/v3/";

      api = api.concat(input["data"]);

      const headers = {
        Authorization: input["authKey"],
      };
      let reqParams = "";
      const pager = "count=" + input["count"] + "&anchor=" + input["page"];
      reqParams = reqParams.concat(pager);

      const res = getSyncCall(api, headers, reqParams);
      return res;
    } catch (e) {
      console.log(e);
    }
  }
);

const auditEventsSlice = createSlice({
  name: "auditEventsSlice",
  initialState,
  extraReducers: {
    [getOrderEvents.pending]() {
      console.log("pending");
    },
    [getOrderEvents.fulfilled](state, { payload }) {
      state.orderEvents = payload;
    },
    [getOrderEvents.rejected](state, { payload }) {
      state.orderSummaryError = "Rejected";
      state.orderEvents = payload;
    },
    [getOrderAuditEvents.pending]() {
    },
    [getOrderAuditEvents.fulfilled](state, { payload }) {
      state.orderHeaderResponse = payload;
    },
    [getOrderAuditEvents.rejected](state, { payload }) {
      state.orderHeaderError = "Rejected";
      state.orderHeaderResponse = payload;
    },
  },
  reducers: {
    resetState(state) {
      state = initialState;
    },
    setGlobalRegion(state, {payload}){
      state.globalRegion = payload;
    },
    setSearchParameters(state, { payload }) {
      state.searchParameters[payload.key] = payload.value;
    },
    updateOrderHeaderInfoDisplay(state, { payload }) {
      state.orderHeaderInfoDisplay = payload;
    },
    setActiveTab(state, { payload }) {
      state.activeTab = payload;
    },
    setAuditFlag(state, auditFlag) {
      state.auditFlag = auditFlag;
    },
    setOrdersPagination(state, { payload }) {
      state.ordersPagination = {...payload};
    },
    setOrderHeaderFlag(state, orderHeaderFlag) {
      state.orderHeaderFlag = orderHeaderFlag;
    },
  },
});

export const {
  resetState,
  setSearchParameters,
  updateOrderHeaderInfoDisplay,
  setActiveTab,
  setGlobalRegion,
  setAuditFlag,
  setOrderHeaderFlag,
  setOrdersPagination,
} = auditEventsSlice.actions;

export default auditEventsSlice.reducer;
