import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import appSlice from 'reducer/appSlice';
import OktaSlice from 'reducer/oktaSlice';
import auditEventsSlice from 'reducer/auditEventsSlice';

const reducers = combineReducers({
  appSliceReducer: appSlice,
  auditEvents: auditEventsSlice,
  oktaSliceReducer: OktaSlice
});

// const persistConfig = {
//   key: 'app',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  // reducer: persistedReducer,
  reducer: reducers,
  devTools: process.env.REACT_APP_ENV !== 'PROD',
  middleware: [thunk],
});


// export const persistor = persistStore(store);
