import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { store } from "./store/store";
import App from "./components/app/App";

import "./index.css";
// import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(
  <div style={{}}>
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<div>Fetching....</div>}>
            <App />
            {/* <PodiumMain/> */}
          </Suspense>
        </Router>
      </Provider>
    </React.StrictMode>
  </div>,
  document.getElementById("root")
);
